import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Card, Form, Table } from 'react-bootstrap';
import { checkCourierServiceAvailability, fetchAllDataAPI, getOrderDetailsForCourier, pushServiceableOrderForShipping } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

const AssignCourier = () => {

    const [isLoading, setIsloading] = useState(false);
    const dispatchid = localStorage.getItem('dispatchdata');
    const checkvalues = localStorage.getItem('checkvalues');
    const navigate = useNavigate();
    const [courier, setCourier] = useState([]);
    const [odetail, SetODetails] = useState([]);
    const [checkorder, setCheckedOrder] = useState([]);

    useEffect(() => {
        if (dispatchid) {
            navigate('/assign-courier');
            getAllActiveCourier();
            getOrderDetailForCourier();
        }
        else {
            navigate("/");
        }
    }, [dispatchid])

    const getOrderDetailForCourier = () => {
        setIsloading(true);
        getOrderDetailsForCourier('dispatch-get-order-details-for-courier', checkvalues).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                SetODetails(...[res.data.dorder]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection!!");
            }
        })
    }
    const getAllActiveCourier = () => {
        fetchAllDataAPI('dispatch-get-courier-partner-data').then((res) => {
            if (res.data.status === 1) {
                setCourier(...[res.data.courier]);
            }
            else {
                toast.warning("Check your connection!!");
            }
        });
    }
    const handleCheckServiceAvailability = (e) => {
        setIsloading(true);
        setCheckedOrder([]);
        const courier = e.target.value;
        localStorage.setItem("setcourier", courier);
        if (courier != "") {
            const data = {
                courier: courier,
                orderid: checkvalues
            };
            checkCourierServiceAvailability('dispatch-check-courier-service-availabilty', data).then((res) => {
                if (res.data.status === 1) {
                    setIsloading(false);
                    SetODetails(...[res.data.dorder]);
                }
            })
        }
        else {
            toast.warning("Kindly select the courier name!!");
        }
    }
    const handleCheckbox = (e) => {
        let isSelected = e.target.checked;
        const { name, checked } = e.target;
        let value = parseInt(e.target.value);

        if (name === "allSelect") {
            let temOrder = odetail.map(record => { return { ...record, isChecked: checked } });
            SetODetails(temOrder);
            if (odetail.length === checkorder.length) {
                setCheckedOrder([]);
            }
            else {
                const postid = odetail.map((item) => {
                    return item.crm_order_id;
                })
                setCheckedOrder(postid);
            }
        }
        else {
            let temOrder = odetail.map(record => record.crm_order_id === name ? { ...record, isChecked: checked } : record);
            SetODetails(temOrder);
            if (isSelected) {
                setCheckedOrder([...checkorder, value]);
            }
            else {
                setCheckedOrder((prevData) => {
                    return prevData.filter((id) => {
                        return id != value;
                    })
                })
            }
        }
    };
    const handleGenerateDocket = () => {
        setIsloading(true);
        if (checkorder != "") {
            const setdata = {
                couriername: localStorage.getItem("setcourier"),
                checkedorder: checkorder.toString()
            }
            pushServiceableOrderForShipping('dispatch-push-order-for-shipping', setdata).then((res) => {
                if (res.data.status === 1) {
                    setIsloading(false);
                    getOrderDetailForCourier();
                    toast.success("Order has been pushed for Shipment");
                }
                else {
                    toast.warning("Please check your connection");
                }
            })
        }
        else {
            setIsloading(false);
            toast.warning("Kindly select the serviceable order at least one");
        }
    }
    return (
        <div className="myprofile">
            <Helmet>
                <title>SATKARTAR:DISPATCH::ASSIGN COURIER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center justify-content-between">
                        <div className="d-flex">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Assign Courier</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-flex pull-right">
                            <Form.Label style={{ marginRight: "5px", width: "100%" }}>Select Courier</Form.Label>
                            <Form.Select
                                size='md'
                                onChange={handleCheckServiceAvailability}
                                className="courierSelect"
                            >
                                <option value="">Select Action</option>
                                {courier?.map((courier, index) => (
                                    <option key={index} value={courier?.courier_name}>{courier?.courier_name}</option>
                                ))}
                            </Form.Select>
                        </div>
                        <div>
                            <span className="btngenerate" onClick={handleGenerateDocket}>Generate Docket No</span>
                        </div>
                    </div>
                    <Card>
                        <Table className="table-sm" responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Order Number</th>
                                    <th>Total Items</th>
                                    <th>Height</th>
                                    <th>Length</th>
                                    <th>Bredth</th>
                                    <th>Weight</th>
                                    <th>Serviceable Status</th>
                                    <th>Dispatched Date</th>
                                    <th>
                                        Checked
                                    </th>
                                    <th>Courier Name</th>
                                    <th>ETD</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            {Array.from({ length: 12 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 12 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 12 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 12 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 12 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                    </>
                                    :
                                    odetail ?
                                        odetail?.map((odetail, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{odetail?.order_number}</td>
                                                <td>{odetail?.order_item}</td>
                                                <td>{odetail?.order_height} cm</td>
                                                <td>{odetail?.order_length} cm</td>
                                                <td>{odetail?.order_bredth} cm</td>
                                                <td>{odetail?.order_weight} kg</td>
                                                <td>
                                                    {(() => {
                                                        if (odetail?.order_serviceava === "Serviceable") {
                                                            return (
                                                                <Badge bg="success">{odetail?.order_serviceava}</Badge>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <Badge bg="danger">{odetail?.order_serviceava}</Badge>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td>{moment(odetail?.order_ddate).format('Do MMMM YYYY')}</td>
                                                <td>
                                                    {(() => {
                                                        if (odetail?.order_serviceava === "Serviceable") {
                                                            return (
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name={odetail?.crm_order_id}
                                                                    value={odetail?.crm_order_id}
                                                                    checked={odetail?.isChecked || false}
                                                                    onChange={handleCheckbox}
                                                                />
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td>
                                                    <p>{odetail?.order_shipname}
                                                        <small className="shippingid">
                                                            <Badge bg="success">
                                                                {odetail?.order_shipid}
                                                            </Badge>
                                                        </small>
                                                    </p>
                                                </td>
                                                <td>{odetail?.order_etd}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td>Data not founds...</td>
                                        </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Order Number</th>
                                    <th>Total Items</th>
                                    <th>Height</th>
                                    <th>Length</th>
                                    <th>Bredth</th>
                                    <th>Weight</th>
                                    <th>Serviceable Status</th>
                                    <th>Dispatched Date</th>
                                    <th>Select All</th>
                                    <th>Shipping Name</th>
                                    <th>ETD</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Card>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AssignCourier