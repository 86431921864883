import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import logo from "../assets/logo.webp";
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleDataFromDatabase } from '../utils/api';
import moment from 'moment';
import { numberFormat } from '../components/numberFormat';

const Invoice = () => {
    const { slug } = useParams();
    const [invoicedata, setInvoice] = useState([]);
    const [pinvoicedata, setPInvoice] = useState([]);
    const whouseid = localStorage.getItem('whousedata');
    const navigate = useNavigate();

    useEffect(() => {
        if (whouseid) {
            navigate(`/order-invoice/${slug}`);
            getInvoiceData(slug)
        }
        else {
            navigate("/");
        }
    }, [whouseid, slug]);

    const getInvoiceData = (slug) => {
        getSingleDataFromDatabase(`member-get-order-invoice-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInvoice(...[res.data.invoice]);
                setPInvoice(...[res.data.pinvoice]);
            }
        })
    }

    return (
        <div className="invoice">
            <Helmet>
                <title>SATKARTAR:MEMBER::INVOICE</title>
            </Helmet>
            <ContentWrapper>
                <div className="mbottom">
                    <div className="card radius-10 overflow-hidden w-100">
                        <div className="invoiceSection">
                            <div className="header">
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="logo">
                                            <img src={logo} alt={logo} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="invoice">
                                            <h2>Invoice</h2>
                                            <p>Date:- <b>{moment(invoicedata.created_date).format('Do MMMM YYYY')}</b></p>
                                            <p>Invoice#:- <b>{slug}</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="billing">
                                            <h5>Billing TO</h5>
                                            <div>
                                                <div className="billingtr">
                                                    <p>Name:</p>
                                                    <p>SATKARTAR SHOPPING LTD.</p>
                                                </div>
                                                <div className="billingtr">
                                                    <p>City:</p>
                                                    <p>New Delhi</p>
                                                </div>
                                                <div className="billingtr">
                                                    <p>State:</p>
                                                    <p>Delhi</p>
                                                </div>
                                                <div className="billingtr">
                                                    <p>Address:</p>
                                                    <p>109, 1st Floor, Mercantile House, 15 K G Marg</p>
                                                </div>
                                                <div className="billingtr">
                                                    <p>Pincode:</p>
                                                    <p>110001</p>
                                                </div>
                                                <div className="billingtr">
                                                    <p>Mobile:</p>
                                                    <p>9319719547</p>
                                                </div>
                                                <div className="billingtr">
                                                    <p>Email:</p>
                                                    <p>info@macamo.in</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="shipping">
                                            <h5>Shipping TO</h5>
                                            <div>
                                                <div className="shippingtr">
                                                    <p>Name:</p>
                                                    <p>{invoicedata?.order_name}</p>
                                                </div>
                                                <div className="shippingtr">
                                                    <p>City:</p>
                                                    <p>{invoicedata?.order_city}</p>
                                                </div>
                                                <div className="shippingtr">
                                                    <p>State:</p>
                                                    <p>{invoicedata?.order_state}</p>
                                                </div>
                                                <div className="shippingtr">
                                                    <p>Address:</p>
                                                    <p>{invoicedata?.order_address}</p>
                                                </div>
                                                <div className="shippingtr">
                                                    <p>Pincode:</p>
                                                    <p>{invoicedata?.order_pincode}</p>
                                                </div>
                                                <div className="shippingtr">
                                                    <p>Mobile:</p>
                                                    <p>{invoicedata?.order_mobile}</p>
                                                </div>
                                                <div className="shippingtr">
                                                    <p>Remarks:</p>
                                                    <p>{invoicedata?.order_remarks}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-4">
                                    <div className="col-12 col-lg-12 col-xl-12">
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr className="producttr">
                                                    <th>#</th>
                                                    <th>Product Name</th>
                                                    <th>QTY</th>
                                                    <th>Product Price</th>
                                                    <th>Selling Price</th>
                                                    <th>Sub Total</th>
                                                    <th>Sell Price</th>
                                                    <th>Disc Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pinvoicedata?.map((pinvoicedata, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{pinvoicedata?.p_name}</td>
                                                        <td>{pinvoicedata?.p_qty}</td>
                                                        <td>{numberFormat(pinvoicedata?.p_price)}</td>
                                                        <td>{numberFormat(pinvoicedata?.p_selling_price)}</td>
                                                        <td>{numberFormat(pinvoicedata?.p_sub_total)}</td>
                                                        <td>{numberFormat(pinvoicedata?.p_tsell_price)}</td>
                                                        <td>{numberFormat(pinvoicedata?.p_disc_price)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="subtotalsection">
                                            <div className="subtotal">
                                                <p>Sub Total:</p>
                                                <p>{numberFormat(invoicedata?.sub_total)}</p>
                                            </div>
                                            <div className="subtotal">
                                                <p>GST:</p>
                                                <p>₹0</p>
                                            </div>
                                            <div className="subtotal">
                                                <p>Shipping Charge:</p>
                                                <p>₹0</p>
                                            </div>
                                            <div className="subtotal">
                                                <p>Payment Method:</p>
                                                <p>{invoicedata?.payment_method}</p>
                                            </div>
                                            <div className="subtotal">
                                                <p>Discount:</p>
                                                <p><b>{numberFormat(invoicedata?.total_disc)}</b></p>
                                            </div>
                                            <div className="subtotal">
                                                <p>Advance:</p>
                                                <p><b>{numberFormat(invoicedata?.order_advance_amt)}</b></p>
                                            </div>
                                            <div className="subtotal">
                                                <p>Balance:</p>
                                                <p><b>{numberFormat(invoicedata?.total_balance)}</b></p>
                                            </div>
                                            <div className="subtotal">
                                                <p>Grand Total:</p>
                                                <p><b className="grandtotal">{numberFormat(invoicedata?.grand_total)}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Invoice