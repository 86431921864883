import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { useNavigate } from 'react-router-dom'
import { getSingleDataFromDatabase } from '../utils/api'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'

const MyProfile = () => {
    const dispatchid = localStorage.getItem('dispatchdata');
    const [dispatch, setDispatch] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        if (dispatchid) {
            getDashboardSingleEmpData(dispatchid);
        }
        else {
            navigate("/");
        }
    }, [dispatchid]);

    const getDashboardSingleEmpData = (dispatchid) => {
        setIsloading(true);
        getSingleDataFromDatabase(`dispatch-get-single-data/${dispatchid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setDispatch(...[res.data.dispatchdata]);
            }
            else {
                setIsloading(true);
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="myprofile">
            <Helmet>
                <title>SATKARTAR:DISPATCH::MY PROFILE</title>
            </Helmet>
            <ContentWrapper>
                <div className="row">
                    <div className="col-6 col-lg-6 col-xl-6">
                        <div className="card radius-10 overflow-hidden w-100">
                            <div className="card-header border-bottom bg-transparent">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h6 className="mb-0" style={{ color: "#03a9f4", fontWeight: "500" }}>My Profile</h6>
                                    </div>
                                </div>
                            </div>
                            {isLoading ?
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Name:</span>
                                                <span className="value">{dispatch?.dispatch_name}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Mobile:</span>
                                                <span className="value">{dispatch?.dispatch_mobile}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Email:</span>
                                                <span className="value">{dispatch?.dispatch_email}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Last Login:</span>
                                                <span className="value">{dispatch?.dispatch_last_login}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyProfile