import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import logo from "../assets/ilogo.webp"
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import { generateLableForShipping } from '../utils/api';
import moment from 'moment';
import { numberFormat } from '../components/numberFormat';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

const GenerateLable = () => {
    const { slug } = useParams();
    const reportTemplateRef = useRef(null);
    const [order, setOrder] = useState([]);
    const [porder, setPOrder] = useState([]);

    useEffect(() => {
        getOrderDetails(slug);
    }, [slug]);


    const getLableGenerate = async () => {
        const input = reportTemplateRef.current;
        try {
            const canvas = await html2canvas(input);
            const imgData = canvas.toDataURL("image/png");
            const doc = new jsPDF({
                orientation: "landscape",
                unit: "in",
                format: [8, 5]
            });

            const width = doc.internal.pageSize.getWidth();
            const height = (canvas.height * width) / canvas.width;
            doc.addImage(imgData, "PNG", 0, 0, width, height);
            doc.save(`lable-${slug}.pdf`);
        }
        catch (error) {
            toast.warning("Something went wrong!!");
        }
    }
    const getOrderDetails = (slug) => {
        generateLableForShipping('dispatch-generate-lable-for-other-shipping', slug).then((res) => {
            if (res.data.status === "1") {
                setOrder(...[res.data.order]);
                setPOrder(...[res.data.porder]);
            }
        })
    }
    return (
        <div className="lablegenerate" ref={reportTemplateRef}>
            <button onClick={getLableGenerate}>Generate</button>
            <div className="main">
                <div className="headersection">
                    <div className="logo">
                        <div>
                            <img src={logo} alt="Satkartar" />
                        </div>
                        <div>
                            <QRCode
                                size={80}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value="https://www.satkartar.co.in/"
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                    <div className="address">
                        <p className="cname">Sat Kartar Shopping Ltd.</p>
                        <p><span>City:</span><b> New Delhi</b></p>
                        <p><span>State:</span><b> Delhi</b></p>
                        <p><span>Address:</span><b> 6th Floor, Unit Number 603, Mercantile</b></p>
                        <p><span>Pincode:</span><b> 110001</b></p>
                        <p><span>Mobile:</span><b> 9310006359</b></p>
                        <p><span>Email:</span><b> info@satkartar.in</b></p>
                    </div>
                    <div className="invoice">
                        <p><span>Invoice No:</span><b> {order?.order_number}</b></p>
                        <p><span>Order Date:</span><b> {moment(order?.created_date).format('Do MMMM YYYY')}</b></p>
                        <p><span>Payment Method:</span> <b> {order?.payment_method}</b></p>
                        <p><span>Courier Name:</span> <b> {order?.ocourier_name}</b></p>
                    </div>
                </div>
                <div className="body">
                    <div className="inner">
                        <div className="address">
                            <p>DELIVER TO</p>
                            <p><span>Name:</span><b>{order?.order_name}</b></p>
                            <p><span>City:</span><b> {order?.order_city}</b></p>
                            <p><span>State:</span><b> {order?.order_state}</b></p>
                            <p><span>Address:</span><b> {order?.order_address}</b></p>
                            <p><span>Pincode:</span><b> {order?.order_pincode}</b></p>
                            <p><span>Mobile:</span><b> {order?.order_mobile}</b></p>
                            <p><span>Country:</span><b> {order?.order_country}</b></p>
                        </div>
                        <div className="barcode">
                            <p>ORDER ID</p>
                            <Barcode height={40} textAlign="center" value={order?.order_number} />
                        </div>
                        <div className="invoice">
                            <p>PREPAID ORDER</p>
                            <Barcode height={40} marginLeft={2} textAlign="left" value={order?.awb_number} />
                            <p style={{ marginTop: "5px" }}><span>AWB No:</span><b> {order?.awb_number}</b></p>
                            <p><span>Weight:</span><b> {porder?.length}00gm</b></p>
                            <p><span>Dimesion:</span><b> 2 x 2 x 2</b></p>
                            <p><span>Order ID:</span><b> {order?.order_number}</b></p>
                            <p><span>Order Date:</span><b> {moment(order?.created_date).format('Do MMMM YYYY')}</b></p>
                            <p><span>Pieces:</span><b> {porder?.length}</b></p>
                        </div>
                    </div>
                    <div className="orderdetails">
                        <table className="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product Name</th>
                                    <th>QTY</th>
                                    <th>Product Price</th>
                                    <th>Sell Price</th>
                                    <th>Discount Price</th>
                                    <th>Subtotal</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {porder?.map((porder, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{porder?.p_name}</td>
                                        <td>{porder?.p_qty}</td>
                                        <td>{numberFormat(porder?.p_price)}</td>
                                        <td>{numberFormat(porder?.p_selling_price)}</td>
                                        <td>{numberFormat(porder?.p_disc_price)}</td>
                                        <td>{numberFormat(porder?.p_sub_total)}</td>
                                        <td>{numberFormat(porder?.p_tsell_price)}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={7}><p className="tablefooter">GST:</p></th>
                                    <th colSpan={8}>₹0.0</th>
                                </tr>
                                <tr>
                                    <th colSpan={7}><p className="tablefooter">SGST:</p></th>
                                    <th colSpan={8}>₹0.0</th>
                                </tr>
                                <tr>
                                    <th colSpan={7}><p className="tablefooter">Total Discount:</p></th>
                                    <th colSpan={8}>{numberFormat(order?.total_disc)}</th>
                                </tr>
                                <tr>
                                    <th colSpan={7}><p className="tablefooter">Total Amount:</p></th>
                                    <th colSpan={8}>{numberFormat(order?.sub_total)}</th>
                                </tr>
                                <tr>
                                    <th colSpan={7}><p className="tablefooter">Grand Total:</p></th>
                                    <th colSpan={8}><b>{numberFormat(order?.grand_total)}</b></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="footer">
                    <p>Return Address: A - 20, Rohini Sec-19, Near Kamla Karan Gas Agency, New Delhi, Delhi, 110042</p>
                </div>
            </div>
        </div>
    )
}

export default GenerateLable